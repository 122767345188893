<template>
  <div class="container has-background-white-ter">
    <section class="hero is-medium is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <div class="column is-8-desktop is-offset-2-desktop">
              <h1 class="title is-2 is-spaced">
                Visitor!
              </h1>
              <h2 class="subtitle is-4">
                  Congratulations! You're running the <strong>Bulma start</strong> project.
                  <br>
                  It includes everything you need to <strong>build your own website</strong> with Bulma.
              </h2>

            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="section">
      <div class="columns is-centered">
        <div class="column is-3">
          <div class="card">
            <header class="card-header">
              <p class="card-header-title">Authenticated User</p>
            </header>
            <div class="card-content">
              <div class="media">
                <div class="media-left">
                  <figure class="image is-48x48">
                    <img v-bind:src="user.photoURL" v-bind:alt="user.displayName"/>
                  </figure>
                </div>
                <div class="media-content">
                  <p class="title is-4">{{ user.displayName }}</p>
                  <p class="subtitle is-6">{{ user.email }}</p>
                  <button @click="test()">Wibble</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

//import api from 'helpers/apicalls';

export default {
  name: "home",

  data: function() {
    return {
      user: firebase.auth().currentUser,
      students: []
    };
  },
  methods: {

    test: async function (event) {

      try {
        alert(event)
        let data =  await this.$api.get("identifiers/", true)

        this.students = data
      }
      catch(error) {
        alert("Wibble: "+error)
      }
    }

  },



};
</script>
